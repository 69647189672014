//------------------------------------------------------------------------
//
// PENTING KALAU MAU PUSH GANTI API_URL MENJADI https://10.99.0.185:4000/ 
//
//------------------------------------------------------------------------

export const urls = {
    'API_URL': "https://my-json-server.typicode.com/galihweb000haha/fakeserverfornusa/",
    'STRAPI_UPLOAD': "http://localhost:1337/",
    'GRAPHQL_URL': "http://localhost:1337/graphql/",
    'STRAPI_URL': "http://localhost:1337/api/",
    // 'API_URL': "http://localhost:4000/",
    'STRAPI_UPLOAD': "http://localhost:1337/",
    'GRAPHQL_URL': "http://localhost:1337/graphql/",
    'STRAPI_URL': "http://localhost:1337/api/",
    'FONT_URL': "https://fonts.googleapis.com/css?family=Plus+Jakarta+Sans:300,400,600,700&amp;lang=en",
    'JQUERY_URL': "https://code.jquery.com/jquery-3.7.0.slim.min.js",
    'JQUERY_DandS_URL': "http://localhost:5020/static/js/jquery.dragnscroll.js",
    'GALIH_SCRIPT': "http://localhost:5020/static/js/galihnusascript.js",
    'GSAP_URL': 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.5/gsap.min.js',
    'SCROLL_TRIGGER_SCRIPT' : 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.9.1/ScrollTrigger.min.js',
} 
