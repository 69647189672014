import React, { useState, useEffect } from 'react';
import { CTAComponent } from "../components/"
import {urls} from '../utils/constants'
import axios from "axios"
import { useParams } from 'react-router-dom';

function BlogDetails() {
    const [blog, setBlog] = useState(null);
    const { documentId } = useParams();

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }
    useEffect(() => {
        const fetchBlog = async () => {
            const query = `
                query {
                    blogs (filters: { documentId: { eq: "${documentId}" } }) {
                        documentId
                        title
                        summary
                        publishedAt
                        banner {
                            url
                        }
                        category {
                            name
                        }
                        reading_time
                        content
                        author {
                            name
                            photo {
                                url
                            }
                        }
                    }
            }`

            try {
                const response = await axios.post(urls.GRAPHQL_URL, {
                    query: query,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setBlog(response.data.data.blogs[0]);

            } catch (error) {
                console.error("Error fetching blogs:", error);
            }
            
            // try {
            //     const response = await axios(`${urls.STRAPI_URL}blogs/${documentId}?populate=*`, {
            //         method: 'GET',
            //         headers: {
            //             'Content-Type': 'application/json',
            //         },
            //         withCredentials: true,
            //         credentials: 'same-origin',
            //     });
            //     setBlog(response.data.data);
            // } catch (error) {
            //     console.log(error);
            // }
        };

        fetchBlog();
    }, [documentId, blog]);

    // useEffect(() => {
    //     console.log(blog)
    // }, []);

    return (
        <>
        { blog ? 
            <>
                <section>
                    <div className='w-[800px] mx-auto text-center'>
                        <p className='text-medium font-semibold text-[#3B82F6] mb-[16px]'>{blog.category?.name} - {blog.reading_time} min read</p>
                        <h1 className='display-lg font-semibold mb-[24px]'>{blog.title}</h1>
                        <p className='text-extralarge font-medium'>{blog.summary}</p>
                        {/* AUTHOR */}
                        <div className='flex flex-row mt-10 justify-center items-center'>
                            <div className='w-12 h-12 rounded-full bg-cover border' style={{backgroundImage: `url(${urls.STRAPI_UPLOAD + blog.author?.photo.url})`}}></div>
                            <div className='flex flex-col ml-3'>
                                <p className='text-left text-large font-semibold text-black'>{blog.author?.name}</p>
                                <p>{formatDate(blog.publishedAt)}</p>
                            </div>
                        </div>
                    </div>
                    {/* BANNER */}
                    <div className="w-full bg-cover lg:h-custom-516 h-custom-250 border mt-24 mb-16" style={{backgroundImage: `url(${urls.STRAPI_UPLOAD + blog.banner.url})`}}></div>
                    {/* CONTENT */}
                    <div className="w-[800px] mx-auto">
                    {blog.content && blog.content.map((item, index) => {
                        switch (item.type) {
                            case 'heading':
                                return (
                                    <>
                                    { item.level === 1 ? 
                                        <h1 className="display-2xl font-semibold">
                                            {item.children.map((child, _) => (
                                                child.text
                                            ))}
                                        </h1>
                                    : item.level === 2 ? 
                                        <h2 className="display-xl font-semibold">
                                            {item.children.map((child, _) => (
                                                child.text
                                            ))}
                                        </h2>
                                    : item.level === 3 ? 
                                        <h3 className="display-lg font-semibold">
                                            {item.children.map((child, _) => (
                                                child.text
                                            ))}
                                        </h3>
                                    : item.level === 4 ? 
                                        <h4 className="display-md font-semibold">
                                            {item.children.map((child, _) => (
                                                child.text
                                            ))}
                                        </h4>
                                    : item.level === 5 ? 
                                        <h5 className="display-sm font-semibold">
                                            {item.children.map((child, _) => (
                                                child.text
                                            ))}
                                        </h5>  
                                    : 
                                        <h6 className="display-xs font-semibold">
                                            {item.children.map((child, _) => (
                                                child.text
                                            ))}
                                        </h6>
                                    }
                                    </>
                                    
                                );
                            case 'paragraph':
                                return (
                                    <p className="text-lg text-custom-paragraph my-4">
                                        {item.children.map((child, childIndex) => (
                                            child.text
                                        ))}
                                    </p>
                                );
                            case 'list':
                                return (
                                    <div>
                                        { item.format === 'unordered' ? 
                                            <ul className='list-disc'>
                                                { item.children.map((listItem, listIndex) => (
                                                    <li>{listItem.children.map((child) => child.text).join(' ')}</li>

                                                ))}
                                            </ul>
                                            :
                                            <ol className='list-decimal'>
                                                { item.children.map((listItem, listIndex) => (
                                                    <li>{listItem.children.map((child) => child.text).join(' ')}</li>

                                                ))}
                                            </ol>
                                        }
                                    </div>
                                )
                            case 'image':
                                return (    
                                    <>
                                        <img src={`${item.image.url}`} alt="Addition Pict" />

                                    </>
                                )
                            default:
                                return null;
                        }
                    })}
                    </div>

                </section>
                {/* <section id="BlogDetails">
                    <div className="container">
                        <hgroup>
                            <p className="categandminutes">Technology  •  8 min read</p>
                            <h1>What is Cloud Computing?</h1>
                            <p>Practice of using a network of remote servers hosted on the internet.</p>
                            <div className="author_details">
                                <div className="image"></div>
                                <div className="desc">
                                    <p className="name">John Doe</p>
                                    <p className="date">1 January 2024</p>
                                </div>
                            </div>
                        </hgroup>
                    </div>
                    <div className="banner">

                    </div>
                    <div className="container">
                        <article>
                            <h3>Introduction</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Eu aliquam urna purus et non. Nunc sagittis aliquam quam id enim. Metus dictum nulla ultrices nisi id duis. Leo integer risus id a posuere suspendisse augue amet. Sapien sit vulputate et nec sagittis rhoncus proin penatibus. Ut velit condimentum mattis dolor. </p>
                            <p>Blandit morbi et augue ac sit. Sit tincidunt lorem mauris quam commodo tempor placerat montes. Aliquet integer urna mus dui morbi nunc metus lectus ultrices. Enim id mi donec sagittis nisl. Egestas fusce eget interdum nulla tellus. Elementum laoreet habitant lobortis porttitor cursus. Diam dui lorem ac cum duis bibendum. Lobortis nisl gravida in pellentesque. Dolor gravida mauris molestie cras leo at. </p>
                            <p>Faucibus laoreet varius et purus proin neque neque. Eget orci ullamcorper morbi euismod lorem in. Id augue lacus ipsum mauris. Vulputate sollicitudin viverra nisi in sagittis. Leo non faucibus gravida purus laoreet adipiscing fringilla consectetur.</p> 
                        </article>
                    </div>
                </section> */}
            </>
            :
            'Loading ... '
        } 
            <CTAComponent tagline="We run all kinds of IT services that vow your success" title_button="Contact Us" />
        </>
    )
}
export default BlogDetails