import React, { useState, useEffect } from 'react';
import { CTAComponent, SkeletonComponent } from "../components/"
import styled from 'styled-components';
import {urls} from '../utils/constants' 
import axios from "axios"
import { Link } from "react-router-dom";

const Ring = styled.div`
    width: 1200px;
    height: 1200px;
    flex-shrink: 0;
    border-radius: 1200px;
    border: 30px solid #3B82F6;
    filter: blur(50px);
    position: absolute;
    right: -600px;
    top: -700px;
`;
const SmallRing = styled.div`
    width: 600px;
    height: 600px;
    flex-shrink: 0;
    border-radius: 100%;
    border: 10px solid #3B82F6;
    filter: blur(28px);
    position: absolute;
    right: -400px;
    top: -200px;
`

function Blog() {
    const [blog, setBlog] = useState(null);
    const [page, setPage] = useState(1);
    const [pageInfo, setPageInfo] = useState([])

    const changePage = (page) => {
        if (page > 0) {
            setPage(page)
            fetchBlog();
            window.scrollTo({top:0, lef: 0, behavior: "instant"});
        }
    }
    const fetchBlog = async () => {
        // GRAPHQL REQUEST
        const query = `
            query {
                blogs_connection (sort: "id:desc", pagination: { page: ${page}, pageSize: 9 }) {
                    nodes {
                        createdAt
                        documentId
                        title
                        slug
                        summary
                        reading_time
                        banner {
                            url
                        }
                        category {
                            name
                        }   
                    }
                    pageInfo {
                        page
                        pageSize
                        pageCount
                        total
                    }
                    
                }
            }
        `;

        try {
            const response = await axios.post(urls.GRAPHQL_URL, {
                query: query,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(response.data.data.blogs_connection)
            setBlog(response.data.data.blogs_connection.nodes);
            setPageInfo(response.data.data.blogs_connection.pageInfo);
            

        } catch (error) {
            console.error("Error fetching blogs:", error);
        }
    };
    useEffect(() => {
        fetchBlog();
    }, [page]);

    // useEffect(() => {
    //     if (blog) {
    //     }
    // }, [blog]);
    return (
        <>
            <Ring className='hidden lg:block'/>
            <SmallRing className='block lg:hidden' />

            <section className="mx-4 lg:mx-28 my-12 sm:py-0 lg:py-24">
                <h1 className="font-bold lg:font-semibold text-5xl lg:text-7xl leading-[61px] lg:leading-[90px] tracking[-2%]">Our latest technology tips, tricks, insights, and resources, freshly delivered.</h1>
            </section>
            

            { blog ? 
                <section className="sm:mx-4 lg:mx-28 py-0 mb-10 lg:mb-24 border">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                        {blog && blog.map((item, index) => {
                            return (
                                <Link to={`/blog/${item.slug}/${item.documentId}`}>
                                    <div className=''> 
                                        <div className="w-full h-[240px] rounded-lg bg-cover mb-4" style={{backgroundImage: `url(${urls.STRAPI_UPLOAD + item.banner.url})`}}>
                                        </div>
                                        <div className="w-full h-44 flex flex-col gap-y-2">
                                            <p className='text-small font-semibold text-[#3B82F6]'>{item.category?.name} - {item.reading_time} min read</p>
                                            <h3 className='display-xs font-semibold text-wrap'>{item.title.substring(0, 40)} {item.title.length > 39 ? '...' : ''}</h3>
                                            <p className='text-medium font-medium'>{item.summary?.substring(0, 100)} {item.summary?.length > 99 ? '...' : ''} </p>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </section>
                :
                <SkeletonComponent />
            }

            {/* PAGINATION */}
            <div class="flex items-center justify-between sm:px-6 mx-4 lg:mx-20 mb-10 lg:mb-24">
                <div class="flex flex-1 justify-between sm:hidden">
                    <span class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50" onClick={() => changePage(page-1)}>Previous</span>
                    <span class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50" onClick={() => changePage(page+1)}>Next</span>
                </div>
                <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                        <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                            <span class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => changePage(page-1)}>
                                <span class="sr-only">Previous</span>
                                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                    <path fill-rule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" />
                                </svg>
                            </span>
                            
                            {[...Array(pageInfo.pageCount)].map((_, index) => (
                                <>
                                {pageInfo.page === index+1 ? 
                                    <span aria-current="page" class="cursor-pointer relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{index+1}</span>
                                    :
                                    <span class="cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => changePage(index+1)}>{index+1}</span> 
                                }
                                </>
                            ))}

                            {/* <a href="#" aria-current="page" class="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={setPage(p)}>1</a> */}
                            {/* <a href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">2</a> */}
                            {/* <span class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
                            <a href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">1002</a>*/}

                            <span class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => changePage(page+1)}> 
                                <span class="sr-only">Next</span>
                                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                    <path fill-rule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </nav>
                    </div>
                </div>
            </div>

            <CTAComponent tagline="We run all kinds of IT services that vow your success" title_button="Contact Us" />
        </>
    )
}

export default Blog